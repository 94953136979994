import React, { CSSProperties, useMemo } from 'react'
import { Routes, Route, Outlet, useLocation, Location } from 'react-router-dom'
import { Modal } from '@mui/base'
import { ViewWrapper } from './components/ViewWrapper'
import { DialogProvider } from './dialogs'
import {
  StateProvider,
  createStore,
  asyncFlow,
} from '@the-platform-company/appbuilder-react-state'
import { RobotenDnDProvider } from '@the-platform-company/react-drag-drop'
const HomePageView = React.lazy(
  () => import(/* webpackChunkName: "views/home-page" */ './views/HomePage')
)
const HomePageHomeView = React.lazy(
  () => import(/* webpackChunkName: "views/home" */ './views/HomePageHome')
)
const HomePageAboutUsView = React.lazy(
  () =>
    import(/* webpackChunkName: "views/about-us" */ './views/HomePageAboutUs')
)
const HomePageDesignersView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/designers" */ './views/HomePageDesigners'
    )
)
const HomePageDevelopersView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/developers" */ './views/HomePageDevelopers'
    )
)
const HomePagePressView = React.lazy(
  () => import(/* webpackChunkName: "views/press" */ './views/HomePagePress')
)
const HomePageCallbackView = React.lazy(
  () =>
    import(/* webpackChunkName: "views/callback" */ './views/HomePageCallback')
)
const HomePagePrivacyPolicyView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/privacy-policy" */ './views/HomePagePrivacyPolicy'
    )
)
const HomePageCookiePolicyView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/cookie-policy" */ './views/HomePageCookiePolicy'
    )
)
const HomePageUntitledViewView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/untitled-view" */ './views/HomePageUntitledView'
    )
)
const HomePagePricingPageView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/pricing-page" */ './views/HomePagePricingPage'
    )
)
const store = createStore()

const modalStyles: CSSProperties = {
  position: 'fixed',
  zIndex: '1300',
  inset: '0px',
}
const App: React.FC = () => {
  const location = useLocation()

  const state = (location.state as { backgroundLocation?: Location }) ?? {}

  const assets = useMemo(
    () => ({
      cursorPurplePng: '/assets/cursor-purple-png/cursor-purple.png',
      pointRemoteTeamsSvg:
        '/assets/point-remote-teams-svg/point-remote-teams.svg',
      logoAwsPng: '/assets/logo-aws-png/logo-aws.png',
      logoNetlifyPng: '/assets/logo-netlify-png/logo-netlify.png',
      logoGithubPng: '/assets/logo-github-png/logo-github.png',
      pointTimeToMarketSvg:
        '/assets/point-time-to-market-svg/point-time-to-market.svg',
      splashDashboardPng: '/assets/splash-dashboard-png/splash-dashboard.png',
      logoReactPng: '/assets/logo-react-png/logo-react.png',
      pointVendorLockinSvg:
        '/assets/point-vendor-lockin-svg/point-vendor-lockin.svg',
      logoVercelPng: '/assets/logo-vercel-png/logo-vercel.png',
      splashPatternPng: '/assets/splash-pattern-png/splash-pattern.png',
      robotenLogoSvg: '/assets/roboten-logo-svg/roboten-logo.svg',
      robotenLogoWhiteSvg:
        '/assets/roboten-logo-white-svg/roboten-logo-white.svg',
      robotenPeakWhiteSvg:
        '/assets/roboten-peak-white-svg/roboten-peak-white.svg',
      illustrationDesignersPng:
        '/assets/illustration-designers-png/illustration-designers.png',
      illustrationRealDataPng:
        '/assets/illustration-real-data-png/illustration-real-data.png',
      blueTextBgLeftPng: '/assets/blue-text-bg-left-png/blue-text-bg-left.png',
      darkDotPatternSvg: '/assets/dark-dot-pattern-svg/dark-dot-pattern.svg',
      iconBulbSvg: '/assets/icon-bulb-svg/icon-bulb.svg',
      iconStopSvg: '/assets/icon-stop-svg/icon-stop.svg',
      iconUsersSvg: '/assets/icon-users-svg/icon-users.svg',
      iconArrowSvg: '/assets/icon-arrow-svg/icon-arrow.svg',
      robotenWorkerPng: '/assets/roboten-worker-png/roboten-worker.png',
      toggleOnSvg: '/assets/toggle-on-svg/toggle-on.svg',
      logoSalesforcePng: '/assets/logo-salesforce-png/logo-salesforce.png',
      logoAirtablePng: '/assets/logo-airtable-png/logo-airtable.png',
      logoSendgridPng: '/assets/logo-sendgrid-png/logo-sendgrid.png',
      logoGoogleDrivePng: '/assets/logo-google-drive-png/logo-google-drive.png',
      logoShopifyPng: '/assets/logo-shopify-png/logo-shopify.png',
      logoGmailPng: '/assets/logo-gmail-png/logo-gmail.png',
      logoPostgresPng: '/assets/logo-postgres-png/logo-postgres.png',
      logoSlackPng: '/assets/logo-slack-png/logo-slack.png',
      logoGoogleSheetsPng:
        '/assets/logo-google-sheets-png/logo-google-sheets.png',
      logoMailchimpPng: '/assets/logo-mailchimp-png/logo-mailchimp.png',
      logoZapierPng: '/assets/logo-zapier-png/logo-zapier.png',
      logoSqlPng: '/assets/logo-sql-png/logo-sql.png',
      logoMysqlPng: '/assets/logo-mysql-png/logo-mysql.png',
      logoStripePng: '/assets/logo-stripe-png/logo-stripe.png',
      logoTwilioPng: '/assets/logo-twilio-png/logo-twilio.png',
      illustrationGaugeSvg:
        '/assets/illustration-gauge-svg/illustration-gauge.svg',
      iconFeatureComponentsSvg:
        '/assets/icon-feature-components-svg/icon-feature-components.svg',
      iconFeatureVersionSvg:
        '/assets/icon-feature-version-svg/icon-feature-version.svg',
      iconComingSoonSvg: '/assets/icon-coming-soon-svg/icon-coming-soon.svg',
      iconFeatureConnectSvg:
        '/assets/icon-feature-connect-svg/icon-feature-connect.svg',
      iconFeatureResponsiveSvg:
        '/assets/icon-feature-responsive-svg/icon-feature-responsive.svg',
      iconFeatureAuthSvg: '/assets/icon-feature-auth-svg/icon-feature-auth.svg',
      iconFeatureTemplatesSvg:
        '/assets/icon-feature-templates-svg/icon-feature-templates.svg',
      iconFeatureLogicSvg:
        '/assets/icon-feature-logic-svg/icon-feature-logic.svg',
      iconFeatureVendorSvg:
        '/assets/icon-feature-vendor-svg/icon-feature-vendor.svg',
      iconFeatureDndSvg: '/assets/icon-feature-dnd-svg/icon-feature-dnd.svg',
      illustrationHighPerformantCodePng:
        '/assets/illustration-high-performant-code-png/Illustration-high-performant-code.png',
      dashedLineHorizontalSvg:
        '/assets/dashed-line-horizontal-svg/dashed-line-horizontal.svg',
      targetDotSvg: '/assets/target-dot-svg/target-dot.svg',
      illustrationCodePng:
        '/assets/illustration-code-png/illustration-code.png',
      dashedLineSvg: '/assets/dashed-line-svg/dashed-line.svg',
      logoOnpremisePng: '/assets/logo-onpremise-png/logo-onpremise.png',
      logoAzurePng: '/assets/logo-azure-png/logo-azure.png',
      logoGoogleCloudPng: '/assets/logo-google-cloud-png/logo-google-cloud.png',
      logoRobotenSymbolPng:
        '/assets/logo-roboten-symbol-png/logo-roboten-symbol.png',
      faviconPng: '/assets/favicon-png/favicon.png',
      illustrationDevelopers2Png:
        '/assets/illustration-developers2-png/IllustrationDevelopers2.png',
      illustrationDevelopers1Png:
        '/assets/illustration-developers1-png/IllustrationDevelopers1.png',
      illustrationDevelopers3Png:
        '/assets/illustration-developers3-png/IllustrationDevelopers3.png',
      illustrationBuild2Png:
        '/assets/illustration-build2-png/IllustrationBuild2.png',
      illustrationBuild3Png:
        '/assets/illustration-build3-png/IllustrationBuild3.png',
      illustrationBuild1Png:
        '/assets/illustration-build1-png/IllustrationBuild1.png',
      illustrationDesignerPageHeroPng:
        '/assets/illustration-designer-page-hero-png/IllustrationDesignerPageHero.png',
      blueBgPng: '/assets/blue-bg-png/Blue BG.png',
      illustrationDesignersNoHandOverPng:
        '/assets/illustration-designers-no-hand-over-png/IllustrationDesignersNoHandOver.png',
      illustrationDesignerStylePng:
        '/assets/illustration-designer-style-png/IllustrationDesignerStyle.png',
      illustrationDesignersPublishPng:
        '/assets/illustration-designers-publish-png/IllustrationDesignersPublish.png',
      illustrationRobotenWorkerPng:
        '/assets/illustration-roboten-worker-png/IllustrationRobotenWorker.png',
      illustrationRobotenVisionPng:
        '/assets/illustration-roboten-vision-png/illustrationRobotenVision.png',
      illustrationWhoWeArePng:
        '/assets/illustration-who-we-are-png/IllustrationWhoWeAre.png',
      illustrationWorkerPng:
        '/assets/illustration-worker-png/illustrationWorker.png',
      developersheroPng: '/assets/developershero-png/developershero.png',
      pressPng: '/assets/press-png/press.png',
      iconBoxesPng: '/assets/icon-boxes-png/IconBoxes.png',
      iconCogPng: '/assets/icon-cog-png/IconCog.png',
      iconUsersPng: '/assets/icon-users-png/IconUsers.png',
      iconLinkPng: '/assets/icon-link-png/IconLink.png',
      iconTargetPng: '/assets/icon-target-png/IconTarget.png',
      vectorPng: '/assets/vector-png/Vector.png',
      labelPng: '/assets/label-png/Label.png',
      herodashboardPng: '/assets/herodashboard-png/herodashboard.png',
      teamworkPng: '/assets/teamwork-png/teamwork.png',
      iconFacebookPng: '/assets/icon-facebook-png/Icon facebook.png',
      iconInstagramPng: '/assets/icon-instagram-png/Icon instagram.png',
      iconLinkedinPng: '/assets/icon-linkedin-png/Icon Linkedin.png',
      iconTikPng: '/assets/icon-tik-png/Icon tik.png',
      iconTwitterPng: '/assets/icon-twitter-png/Icon twitter.png',
      iconYouPng: '/assets/icon-you-png/Icon you.png',
      labelOrangePng: '/assets/label-orange-png/LabelOrange.png',
      imageDeveloperheroPng:
        '/assets/image-developerhero-png/image.developerhero.png',
      imageRobotenworkemalesittingPng:
        '/assets/image-robotenworkemalesitting-png/image.robotenworkemalesitting.png',
      imageMarketplacePng:
        '/assets/image-marketplace-png/image.marketplace.png',
      imageRobotenworkercollectionPng:
        '/assets/image-robotenworkercollection-png/image.robotenworkercollection.png',
      imageRobotenworkermalefemalePng:
        '/assets/image-robotenworkermalefemale-png/image.robotenworkermalefemale.png',
      imageRobotenworkerfemalePng:
        '/assets/image-robotenworkerfemale-png/image.robotenworkerfemale.png',
      imageWhatyoucanbuildwithroboten_1Png:
        '/assets/image-whatyoucanbuildwithroboten-1-png/image.whatyoucanbuildwithroboten.1.png',
      imageWhatyoucanbuildwithroboten_2Png:
        '/assets/image-whatyoucanbuildwithroboten-2-png/image.whatyoucanbuildwithroboten.2.png',
      imageWhatyoucanbuildwithroboten_3Png:
        '/assets/image-whatyoucanbuildwithroboten-3-png/image.whatyoucanbuildwithroboten.3.png',
      imageRoboteworkermalePngPng:
        '/assets/image-roboteworkermale-png-png/image.roboteworkermale.png.png',
      imageMarketplace1_5Png:
        '/assets/image-marketplace1-5-png/image.marketplace1.5.png',
      imageRobotenworkersidePng:
        '/assets/image-robotenworkerside-png/image.robotenworkerside.png',
      imageDatesourcesPng:
        '/assets/image-datesources-png/image.datesources.png',
      imageNohandoverdesignerpagePng:
        '/assets/image-nohandoverdesignerpage-png/image.nohandoverdesignerpage.png',
      imagePublishPng: '/assets/image-publish-png/image.publish.png',
      imageComponentstylingPng:
        '/assets/image-componentstyling-png/image.componentstyling.png',
      imageDraganddropPng:
        '/assets/image-draganddrop-png/image.draganddrop.png',
      dashboardPng: '/assets/dashboard-png/Dashboard.png',
      patternPng: '/assets/pattern-png/Pattern.png',
      robotPng: '/assets/robot-png/Robot.png',
      oPiconFeatureDndSvg:
        '/assets/o-picon-feature-dnd-svg/OPicon-feature-dnd.svg',
      oPiconBulbSvg: '/assets/o-picon-bulb-svg/OPicon-bulb.svg',
      oPlabelPopularSvg: '/assets/o-plabel-popular-svg/OPlabel-popular.svg',
      oPlogoAwsWebp: '/assets/o-plogo-aws-webp/OPlogo-aws.webp',
      oPillustrationWhatCanYouBuildWithRobotenWebp:
        '/assets/o-pillustration-what-can-you-build-with-roboten-webp/OPillustration-what-can-you-build-with-roboten.webp',
      oPillustrationCodeWebp:
        '/assets/o-pillustration-code-webp/OPillustration-code.webp',
      oPlogoGoogleCloudWebp:
        '/assets/o-plogo-google-cloud-webp/OPlogo-google-cloud.webp',
      oPiconFeatureConnectSvg:
        '/assets/o-picon-feature-connect-svg/OPicon-feature-connect.svg',
      oPiconFeatureLogicSvg:
        '/assets/o-picon-feature-logic-svg/OPicon-feature-logic.svg',
      oPbgBlueSvg: '/assets/o-pbg-blue-svg/OPbg-blue.svg',
      oPiconArrowSvg: '/assets/o-picon-arrow-svg/OPicon-arrow.svg',
      oPiconFeatureAuthSvg:
        '/assets/o-picon-feature-auth-svg/OPicon-feature-auth.svg',
      oPbgPatternWebp: '/assets/o-pbg-pattern-webp/OPbg-pattern.webp',
      oPlogoGmailWebp: '/assets/o-plogo-gmail-webp/OPlogo-gmail.webp',
      oPlogoMailchimpWebp:
        '/assets/o-plogo-mailchimp-webp/OPlogo-mailchimp.webp',
      oPiconFeatureResponsiveSvg:
        '/assets/o-picon-feature-responsive-svg/OPicon-feature-responsive.svg',
      oPiconFeatureTemplatesSvg:
        '/assets/o-picon-feature-templates-svg/OPicon-feature-templates.svg',
      oPlogoGoogleDriveWebp:
        '/assets/o-plogo-google-drive-webp/OPlogo-google-drive.webp',
      oPiconFeatureVendorSvg:
        '/assets/o-picon-feature-vendor-svg/OPicon-feature-vendor.svg',
      oPillustrationHighlyPerformantCodeWebp:
        '/assets/o-pillustration-highly-performant-code-webp/OPillustration-highly-performant-code.webp',
      oPiconStopSvg: '/assets/o-picon-stop-svg/OPicon-stop.svg',
      oPiconUsersSvg: '/assets/o-picon-users-svg/OPicon-users.svg',
      oPlogoNetlifyWebp: '/assets/o-plogo-netlify-webp/OPlogo-netlify.webp',
      oPillustrationDevelopersWebp:
        '/assets/o-pillustration-developers-webp/OPillustration-developers.webp',
      oPlogoGoogleSheetsWebp:
        '/assets/o-plogo-google-sheets-webp/OPlogo-google-sheets.webp',
      oPlogoOnPremiseWebp:
        '/assets/o-plogo-on-premise-webp/OPlogo-on-premise.webp',
      oPillustrationDashboardWebp:
        '/assets/o-pillustration-dashboard-webp/OPillustration-dashboard.webp',
      oPlogoAirtableWebp: '/assets/o-plogo-airtable-webp/OPlogo-airtable.webp',
      oPillustrationTeamworkWebp:
        '/assets/o-pillustration-teamwork-webp/OPillustration-teamwork.webp',
      oPlogoGithubWebp: '/assets/o-plogo-github-webp/OPlogo-github.webp',
      oPillustrationDesignersWebp:
        '/assets/o-pillustration-designers-webp/OPillustration-designers.webp',
      oPlogoMysqlWebp: '/assets/o-plogo-mysql-webp/OPlogo-mysql.webp',
      oPillustrationMarketplaceWebp:
        '/assets/o-pillustration-marketplace-webp/OPillustration-marketplace.webp',
      oPlogoAzureWebp: '/assets/o-plogo-azure-webp/OPlogo-azure.webp',
      oPillustrationGaugeWebp:
        '/assets/o-pillustration-gauge-webp/OPillustration-gauge.webp',
      oPlogoReactWebp: '/assets/o-plogo-react-webp/OPlogo-react.webp',
      oPlogoPostgresqlWebp:
        '/assets/o-plogo-postgresql-webp/OPlogo-postgresql.webp',
      oPlogoSalesforceWebp:
        '/assets/o-plogo-salesforce-webp/OPlogo-salesforce.webp',
      oPlogoSlackWebp: '/assets/o-plogo-slack-webp/OPlogo-slack.webp',
      oPlogoShopifyWebp: '/assets/o-plogo-shopify-webp/OPlogo-shopify.webp',
      oPlogoSendGridWebp:
        '/assets/o-plogo-send-grid-webp/OPlogo-send-grid.webp',
      oPpointRemoteTeamsSvg:
        '/assets/o-ppoint-remote-teams-svg/OPpoint-remote-teams.svg',
      oPlogoZapierWebp: '/assets/o-plogo-zapier-webp/OPlogo-zapier.webp',
      oPlogoSqlServerWebp:
        '/assets/o-plogo-sql-server-webp/OPlogo-sql-server.webp',
      oPlogoVercelWebp: '/assets/o-plogo-vercel-webp/OPlogo-vercel.webp',
      oPpointVendorLockinSvg:
        '/assets/o-ppoint-vendor-lockin-svg/OPpoint-vendor-lockin.svg',
      oPpointTimeToMarketSvg:
        '/assets/o-ppoint-time-to-market-svg/OPpoint-time-to-market.svg',
      oPphotoRobotenWorkerWebp:
        '/assets/o-pphoto-roboten-worker-webp/OPphoto-roboten-worker.webp',
      oPswitchOnSvg: '/assets/o-pswitch-on-svg/OPswitch-on.svg',
      oPlogoStripeWebp: '/assets/o-plogo-stripe-webp/OPlogo-stripe.webp',
      oPlogoTwilioWebp: '/assets/o-plogo-twilio-webp/OPlogo-twilio.webp',
      oPbgBlueRightSvg: '/assets/o-pbg-blue-right-svg/OPbg-blue-right.svg',
      oPillustrationDataSourcesWebp:
        '/assets/o-pillustration-data-sources-webp/OPillustration-data-sources.webp',
      oPillustrationDesignerHeroWebp:
        '/assets/o-pillustration-designer-hero-webp/OPillustration-designer-hero.webp',
      oPbgBlueLeftSvg: '/assets/o-pbg-blue-left-svg/OPbg-blue-left.svg',
      oPillustrationComponentStylingWebp:
        '/assets/o-pillustration-component-styling-webp/OPillustration-component-styling.webp',
      oPillustrationNoHandoverWebp:
        '/assets/o-pillustration-no-handover-webp/OPillustration-no-handover.webp',
      oPillustrationPublishWebp:
        '/assets/o-pillustration-publish-webp/OPillustration-publish.webp',
      oPphotoRobotenWorkerSideWebp:
        '/assets/o-pphoto-roboten-worker-side-webp/OPphoto-roboten-worker-side.webp',
      oPillustrationDragAndDropWebp:
        '/assets/o-pillustration-drag-and-drop-webp/OPillustration-drag-and-drop.webp',
      illustrationMarketplace_2Webp:
        '/assets/illustration-marketplace-2-webp/illustration-marketplace-2.webp',
      illustrationDeveloperHeroWebp:
        '/assets/illustration-developer-hero-webp/illustration-developer-hero.webp',
      illustrationDevelopers_2Webp:
        '/assets/illustration-developers-2-webp/illustration-developers-2.webp',
      oPillustrationDevelopers_2Webp:
        '/assets/o-pillustration-developers-2-webp/OPillustration-developers-2.webp',
      oPillustrationDeveloperHeroWebp:
        '/assets/o-pillustration-developer-hero-webp/OPillustration-developer-hero.webp',
      oPiconBoxesSvg: '/assets/o-picon-boxes-svg/OPicon-boxes.svg',
      oPiconLinkSvg: '/assets/o-picon-link-svg/OPicon-link.svg',
      oPiconCogSvg: '/assets/o-picon-cog-svg/OPicon-cog.svg',
      oPiconTargetSvg: '/assets/o-picon-target-svg/OPicon-target.svg',
      oPphotoRobotenWorkersCollectionWebp:
        '/assets/o-pphoto-roboten-workers-collection-webp/OPphoto-roboten-workers-collection.webp',
      oPphotoRobotenWorkersWebp:
        '/assets/o-pphoto-roboten-workers-webp/OPphoto-roboten-workers.webp',
      oPphotoRobotenWorkerMaleSittingWebp:
        '/assets/o-pphoto-roboten-worker-male-sitting-webp/OPphoto-roboten-worker-male-sitting.webp',
      oPphotoRobotenWorkerMaleStandingWebp:
        '/assets/o-pphoto-roboten-worker-male-standing-webp/OPphoto-roboten-worker-male-standing.webp',
      bestvaluePng: '/assets/bestvalue-png/bestvalue.png',
      heartPng: '/assets/heart-png/heart.png',
      photoRobotenWorkersCollections_620wWebp:
        '/assets/photo-roboten-workers-collections-620w-webp/photo-roboten-workers-collections-620w.webp',
      photoRobotenWorkersCollections_441wWebp:
        '/assets/photo-roboten-workers-collections-441w-webp/photo-roboten-workers-collections-441w.webp',
      photoRobotenWorkersCollections_345wWebp:
        '/assets/photo-roboten-workers-collections-345w-webp/photo-roboten-workers-collections-345w.webp',
      photoRobotenWorkersCollections_275wWebp:
        '/assets/photo-roboten-workers-collections-275w-webp/photo-roboten-workers-collections-275w.webp',
      photoRobotenWorkers_1440wWebp:
        '/assets/photo-roboten-workers-1440w-webp/photo-roboten-workers-1440w.webp',
      photoRobotenWorkers_1024wWebp:
        '/assets/photo-roboten-workers-1024w-webp/photo-roboten-workers-1024w.webp',
      photoRobotenWorkerMaleStanding_395wWebp:
        '/assets/photo-roboten-worker-male-standing-395w-webp/photo-roboten-worker-male-standing-395w.webp',
      photoRobotenWorkers_800wWebp:
        '/assets/photo-roboten-workers-800w-webp/photo-roboten-workers-800w.webp',
      photoRobotenWorkers_640wWebp:
        '/assets/photo-roboten-workers-640w-webp/photo-roboten-workers-640w.webp',
      photoRobotenWorkerMaleStanding_505wWebp:
        '/assets/photo-roboten-worker-male-standing-505w-webp/photo-roboten-worker-male-standing-505w.webp',
      photoRobotenWorkerMaleStanding_315wWebp:
        '/assets/photo-roboten-worker-male-standing-315w-webp/photo-roboten-worker-male-standing-315w.webp',
      photoRobotenWorkerMaleStanding_710wWebp:
        '/assets/photo-roboten-worker-male-standing-710w-webp/photo-roboten-worker-male-standing-710w.webp',
      photoRobotenWorkerMaleSitting_480wWebp:
        '/assets/photo-roboten-worker-male-sitting-480w-webp/photo-roboten-worker-male-sitting-480w.webp',
      photoRobotenWorkerMaleSitting_640wWebp:
        '/assets/photo-roboten-worker-male-sitting-640w-webp/photo-roboten-worker-male-sitting-640w.webp',
      photoRobotenWorkerMaleSitting_320wWebp:
        '/assets/photo-roboten-worker-male-sitting-320w-webp/photo-roboten-worker-male-sitting-320w.webp',
      backgroundSignupJpg:
        '/assets/background-signup-jpg/background-signup.jpg',
      backgroundLoginJpg: '/assets/background-login-jpg/background-login.jpg',
      robotenLogoWhite_2xPng:
        '/assets/roboten-logo-white-2x-png/roboten-logo-white-2x.png',
      robotenLogoBlue_2xPng:
        '/assets/roboten-logo-blue-2x-png/roboten-logo-blue-2x.png',
      socialTwitter_2xPng:
        '/assets/social-twitter-2x-png/social-twitter-2x.png',
      socialInstagram_2xPng:
        '/assets/social-instagram-2x-png/social-instagram-2x.png',
      socialLinkedin_2xPng:
        '/assets/social-linkedin-2x-png/social-linkedin-2x.png',
      socialFacebook_2xPng:
        '/assets/social-facebook-2x-png/social-facebook-2x.png',
      hero1Png: '/assets/hero1-png/hero1.png',
    }),
    []
  )
  return (
    <>
      <StateProvider
        additionalState={{
          assets,
        }}
        store={store}
      >
        <RobotenDnDProvider>
          <DialogProvider>
            <>
              <React.Suspense fallback={null}>
                <Routes location={state?.backgroundLocation ?? location}>
                  <Route
                    path=""
                    element={
                      <ViewWrapper
                        namespace={'f5a947e5-5c6c-457c-bf64-446ca23df77b'}
                      >
                        <HomePageView />
                      </ViewWrapper>
                    }
                  >
                    <Route
                      path="/"
                      element={
                        <ViewWrapper
                          namespace={'65fc7ace-585d-4cf6-b1d4-1fd5d4c2602f'}
                        >
                          <HomePageHomeView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/about-us"
                      element={
                        <ViewWrapper
                          namespace={'eeb878c3-1255-487c-815a-2e1e244f1bf7'}
                        >
                          <HomePageAboutUsView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/designers"
                      element={
                        <ViewWrapper
                          namespace={'510bab14-3885-465f-a46d-d9b3c5d4c882'}
                        >
                          <HomePageDesignersView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/developers"
                      element={
                        <ViewWrapper
                          namespace={'56c5c34e-0c33-47af-9eb5-ed76612929d1'}
                        >
                          <HomePageDevelopersView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/press"
                      element={
                        <ViewWrapper
                          namespace={'2c98daec-f5a0-431d-bba3-93d13d70783c'}
                        >
                          <HomePagePressView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/callback"
                      element={
                        <ViewWrapper
                          namespace={'b57a01b8-7bc8-45bb-a916-7114e661e348'}
                        >
                          <HomePageCallbackView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/privacy-policy"
                      element={
                        <ViewWrapper
                          namespace={'e7619ea8-628d-420a-b255-dcf78091361a'}
                        >
                          <HomePagePrivacyPolicyView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/cookie-policy"
                      element={
                        <ViewWrapper
                          namespace={'aec745de-3778-4ccd-9c85-59d2ba897702'}
                        >
                          <HomePageCookiePolicyView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/untitled-view"
                      element={
                        <ViewWrapper
                          namespace={'53e2846d-be61-416a-b563-1d8ad374f5ed'}
                        >
                          <HomePageUntitledViewView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/pricing"
                      element={
                        <ViewWrapper
                          namespace={'b6779df7-39d5-4b43-b7f9-1d2f3efd7f14'}
                        >
                          <HomePagePricingPageView />
                        </ViewWrapper>
                      }
                    />
                  </Route>
                </Routes>

                {/* Show the modal when a `backgroundLocation` is set */}
                {state?.backgroundLocation && (
                  <Routes>
                    <Route
                      path=""
                      element={
                        <Modal
                          open={!!state?.backgroundLocation}
                          style={modalStyles}
                        >
                          <div>
                            <Outlet />
                          </div>
                        </Modal>
                      }
                    >
                      <Route
                        path=""
                        element={
                          <ViewWrapper
                            namespace={'f5a947e5-5c6c-457c-bf64-446ca23df77b'}
                          >
                            <HomePageView />
                          </ViewWrapper>
                        }
                      >
                        <Route
                          path="/"
                          element={
                            <ViewWrapper
                              namespace={'65fc7ace-585d-4cf6-b1d4-1fd5d4c2602f'}
                            >
                              <HomePageHomeView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/about-us"
                          element={
                            <ViewWrapper
                              namespace={'eeb878c3-1255-487c-815a-2e1e244f1bf7'}
                            >
                              <HomePageAboutUsView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/designers"
                          element={
                            <ViewWrapper
                              namespace={'510bab14-3885-465f-a46d-d9b3c5d4c882'}
                            >
                              <HomePageDesignersView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/developers"
                          element={
                            <ViewWrapper
                              namespace={'56c5c34e-0c33-47af-9eb5-ed76612929d1'}
                            >
                              <HomePageDevelopersView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/press"
                          element={
                            <ViewWrapper
                              namespace={'2c98daec-f5a0-431d-bba3-93d13d70783c'}
                            >
                              <HomePagePressView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/callback"
                          element={
                            <ViewWrapper
                              namespace={'b57a01b8-7bc8-45bb-a916-7114e661e348'}
                            >
                              <HomePageCallbackView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/privacy-policy"
                          element={
                            <ViewWrapper
                              namespace={'e7619ea8-628d-420a-b255-dcf78091361a'}
                            >
                              <HomePagePrivacyPolicyView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/cookie-policy"
                          element={
                            <ViewWrapper
                              namespace={'aec745de-3778-4ccd-9c85-59d2ba897702'}
                            >
                              <HomePageCookiePolicyView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/untitled-view"
                          element={
                            <ViewWrapper
                              namespace={'53e2846d-be61-416a-b563-1d8ad374f5ed'}
                            >
                              <HomePageUntitledViewView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/pricing"
                          element={
                            <ViewWrapper
                              namespace={'b6779df7-39d5-4b43-b7f9-1d2f3efd7f14'}
                            >
                              <HomePagePricingPageView />
                            </ViewWrapper>
                          }
                        />
                      </Route>
                    </Route>
                  </Routes>
                )}
              </React.Suspense>
            </>
          </DialogProvider>
        </RobotenDnDProvider>
      </StateProvider>
    </>
  )
}
export default App
